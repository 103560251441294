<template>
  <div
    class="views_shopping_mail_featured_home"
  >
    <carousel
      v-if="carousel_list && carousel_list.length"
      class="carousel_wrapper"
      :list="carousel_list"
      @handleClick="clickCarousel"
    ></carousel>
    <div class="package_group section"
      v-if="package_list && package_list.length"
    >
      <title-module
        class="section_title_group"
        title="精选教学包"
        @handleMore="clickMore({name: '全部教学包', only: true, type: 'teaching_package'})"
      ></title-module>
      <div class="list_group d-flex align-start flex-wrap">
        <template
          v-for="(item, index) in package_list"
        >
          <teaching-package
            @click.stop.native="clickPackage(item)"
            class="teaching_package mb-10"
            :key="index"
            v-if="index<=5"
            :pselected="item"
          ></teaching-package>
        </template>
      </div>
    </div>
    <div class="lecturer_group section"
      v-if="lecturer_list && lecturer_list.length"
    >
      <!-- sub_title="申请加入合作讲师" -->
      <title-module
        class="section_title_group"
        title="精选合作讲师"
        :show_more="false"
      ></title-module>
      <overlay-scrollbars
        class="overlay_container"
        ref="overlay"
      >

        <div class="list_group d-flex align-start flex-nowrap">
          <template
            v-for="(item, index) in lecturer_list"
          >
            <div
              :key="index"
              class="lecturer_unit"
            >
              <img
                :src="`${$avatar_url}${item.userInfo.thumbnail}`"
                alt=""
                class="avatar"
              >
              <div class="name">{{item.title}}</div>
            </div>
          </template>
        </div>
      </overlay-scrollbars>
    </div>
    <div class="task_group section"
      v-if="task_list && task_list.length"
    >
      <title-module
        class="section_title_group mb-20"
        title="精选推荐"
        @handleMore="clickMore({name: '全部任务模板', only: true, type: 'task'})"
      ></title-module>
      <div
        class="list_group task_list_group d-flex align-start flex-wrap"
        ref="task_list"
      >
        <template
          v-for="(item, index) in task_list"
        >
          <task-module
            v-if="index<task_line_number"
            class="mr-16 task_module"
            :key="index"
            :pselected="item"
            @click.stop.native="viewTask(item)"
          ></task-module>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import Carousel from './Carousel/index.vue'
import TitleModule from '@/views/ShoppingMail/Modules/Common/Title.vue'
import TeachingPackage from '@/views/ShoppingMail/Modules/TeachingPackage/Unit/Major.vue'
import TaskModule from '@/views/ShoppingMail/Modules/Task/Unit/Major.vue'
import { PRotation, PSelected } from '@/types'

export default {
  components: {
    Carousel,
    TitleModule,
    TeachingPackage,
    TaskModule,
  },
  mounted(){
    this.$nextTick(function(){
      this.init();
    })
  },
  data(){
    return{
      carousel_list: [],
      package_list: [],
      task_list: [],
      lecturer_list: [],
      task_line_number: 5, // 任务单行数据
    }
  },
  methods: {
    async init(){
      await this.getData();
      this.$nextTick(function(){
        this.getTaskLineNumber();
      })

    },
    async getData(){
      const res = await this.$global.doPost({
        url: '/projectShop/selectedHome',
      })
      const {message, contents} = res;
      if(message === 'success'){
        const {rotations,selectedPackage,selectedSignUser,selectedTask} = contents;
        const carousel_list = this.$types.transformObjectForList({
          list: rotations,
          obj : PRotation,
        })
        const package_list = this.$types.transformObjectForList({
          list: selectedPackage,
          obj : PSelected,
        })
        const task_list = this.$types.transformObjectForList({
          list: selectedTask,
          obj : PSelected,
        })
        const lecturer_list = this.$types.transformObjectForList({
          list: selectedSignUser,
          obj : PSelected,
        })
        this.$set(this, 'carousel_list', carousel_list);
        this.$set(this, 'package_list', package_list);
        this.$set(this, 'task_list', task_list);
        this.$set(this, 'lecturer_list', lecturer_list);
      }
      return res;
    },
    clickPackage(item){
      // // console.log(item);
      // 追加 package 及id
      // this.$emit('handlePushNavigation', {type:'teaching_package', package_data: item})
      this.$api.shopping_mail.pushNav({type: 'teaching_package', package_data: item});
    },
    /**
     * 教学包预览任务
     */
    viewTask (item) {
      // this.$emit('handlePushNavigation', {type: 'task', task_data: item, payment: true,})
      this.$api.shopping_mail.pushNav({type: 'task', task_data: item, payment: true});
    },
    clickMore (params) {
      const {name, only, type} = params;
      this.$api.shopping_mail.pushNav({type: 'search', search_data:{
        name,
        only,
        type
      }})
    },
    clickActivePage (item) {
      window.open(item.content, '_blank')
    },
    getTaskLineNumber () {
      const unit_width = 200 + 16
      const task_list = this.$refs.task_list
      if (task_list) {
        const { clientWidth: task_list_width } = task_list
        this.task_line_number = Math.floor(task_list_width / unit_width)
      }
    },
    clickCarousel (item) {
      const { type } = item
      if (type === 1) {
        this.viewTask({id:item.content, name: item.title})
      }
      if (type === 2) {
        this.clickPackage({id: item.content, title: item.title})
      }
      if (type === 3) {
        this.clickActivePage(item)
      }
    }
  }
}
</script>


<style lang="scss" scoped>
.views_shopping_mail_featured_home {
  background-color: #fff;
  padding-bottom: 20px;
  .carousel_wrapper {
    padding: {
      top: 20px;
      bottom: 30px;
    }
    margin: 0 auto;
  }
  .section{
    padding: 0 40px;
  }
  .section_title_group{
    padding-top: 30px;
  }
  .list_group{
    padding: 30px 0 40px;
  }
  .teaching_package{
    width: 18.9%;
    margin-right: 1.32%;
    &:nth-of-type(5n){
      margin-right: 0;
    }
  }
  .overlay_container{
    height: 280px;
  }
  .lecturer_unit{
    margin-right: 66px;
    .avatar{
      width: 160px;
      height: 160px;
      border-radius: 50%;
      display: block;
    }
    .name{
      text-align: center;
      font-size: 16px;
      height: 40px;
      line-height: 40px;
    }
  }
  .task_list_group{
    // height: 160px;
    // overflow: hidden;
    padding: 0;
  }
  .nomore{
    width: 100%;
    font-size: 16px;
    color: #D5D9DB;
    line-height: 21px;
    text-align: center;
    padding: 50px 0;
  }
  .task_module{
    width: 200px;
  }
}
</style>

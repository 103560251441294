var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "views_shopping_mail_featured_home" },
    [
      _vm.carousel_list && _vm.carousel_list.length
        ? _c("carousel", {
            staticClass: "carousel_wrapper",
            attrs: { list: _vm.carousel_list },
            on: { handleClick: _vm.clickCarousel },
          })
        : _vm._e(),
      _vm.package_list && _vm.package_list.length
        ? _c(
            "div",
            { staticClass: "package_group section" },
            [
              _c("title-module", {
                staticClass: "section_title_group",
                attrs: { title: "精选教学包" },
                on: {
                  handleMore: function ($event) {
                    return _vm.clickMore({
                      name: "全部教学包",
                      only: true,
                      type: "teaching_package",
                    })
                  },
                },
              }),
              _c(
                "div",
                { staticClass: "list_group d-flex align-start flex-wrap" },
                [
                  _vm._l(_vm.package_list, function (item, index) {
                    return [
                      index <= 5
                        ? _c("teaching-package", {
                            key: index,
                            staticClass: "teaching_package mb-10",
                            attrs: { pselected: item },
                            nativeOn: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.clickPackage(item)
                              },
                            },
                          })
                        : _vm._e(),
                    ]
                  }),
                ],
                2
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.lecturer_list && _vm.lecturer_list.length
        ? _c(
            "div",
            { staticClass: "lecturer_group section" },
            [
              _c("title-module", {
                staticClass: "section_title_group",
                attrs: { title: "精选合作讲师", show_more: false },
              }),
              _c(
                "overlay-scrollbars",
                { ref: "overlay", staticClass: "overlay_container" },
                [
                  _c(
                    "div",
                    {
                      staticClass: "list_group d-flex align-start flex-nowrap",
                    },
                    [
                      _vm._l(_vm.lecturer_list, function (item, index) {
                        return [
                          _c(
                            "div",
                            { key: index, staticClass: "lecturer_unit" },
                            [
                              _c("img", {
                                staticClass: "avatar",
                                attrs: {
                                  src: `${_vm.$avatar_url}${item.userInfo.thumbnail}`,
                                  alt: "",
                                },
                              }),
                              _c("div", { staticClass: "name" }, [
                                _vm._v(_vm._s(item.title)),
                              ]),
                            ]
                          ),
                        ]
                      }),
                    ],
                    2
                  ),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.task_list && _vm.task_list.length
        ? _c(
            "div",
            { staticClass: "task_group section" },
            [
              _c("title-module", {
                staticClass: "section_title_group mb-20",
                attrs: { title: "精选推荐" },
                on: {
                  handleMore: function ($event) {
                    return _vm.clickMore({
                      name: "全部任务模板",
                      only: true,
                      type: "task",
                    })
                  },
                },
              }),
              _c(
                "div",
                {
                  ref: "task_list",
                  staticClass:
                    "list_group task_list_group d-flex align-start flex-wrap",
                },
                [
                  _vm._l(_vm.task_list, function (item, index) {
                    return [
                      index < _vm.task_line_number
                        ? _c("task-module", {
                            key: index,
                            staticClass: "mr-16 task_module",
                            attrs: { pselected: item },
                            nativeOn: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.viewTask(item)
                              },
                            },
                          })
                        : _vm._e(),
                    ]
                  }),
                ],
                2
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }